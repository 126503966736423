import React from "react"
import Layout from "../components/layout"
import ContactInfo from "../components/contactinfo"

export default () => (
    <Layout>
        <title>Kuoste</title>
        <p>Software Development Services. Specialized in C#, C++, Data Integration </p>

        <h3>Reference projects</h3>
        <p> A program that creates Minecraft worlds when provided aerial lidar data and some vectors containing roads, building outlines and water bodies.
            See demo video:
        </p>
        <iframe title="Minecraft world" width="560" height="315" src="https://www.youtube-nocookie.com/embed/R25wgUbRRF4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <ContactInfo></ContactInfo>
  </Layout>
)