import React from "react"

export default () => (
    <div>
        <h3>Contact</h3>
        <p>
            Kuoste <br/>
            00550 Helsinki <br/>
            Finland
        </p>
        <p>
            info@kuoste.fi <br/>
            <a href="https://www.linkedin.com/company/kuoste">LinkedIn</a> <br/>
            Business ID  2963138-3 <br/>
        </p>
    </div>
)